.fc .fc-scrollgrid-section-sticky > *,
.fc .fc-list-sticky .fc-list-day > * {
  background-color: transparent;
}

.fc .fc-list-event:hover td {
  background-color: var(--chakra-ring-color);
}
.fc .fc-toolbar.fc-header-toolbar {
  margin: 1em 0;
}
