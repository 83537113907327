.light-theme {
  --light-gray: var(--chakra-colors-gray-100);
  --gray: var(--chakra-colors-gray-300);
  --brand700: var(--chakra-colors-brand-600);
  --brand600: var(--chakra-colors-brand-500);
  --brand500: var(--chakra-colors-gray-400);
  --brand400: var(--chakra-colors-gray-300);
  --brand300: var(--chakra-colors-gray-200);
  --brand200: var(--chakra-colors-gray-200);
  --brand100: var(--chakra-colors-gray-100);

  --monthBackground: var(--chakra-colors-white);
  --text: var(--chakra-colors-black);
  --negative-text: var(--chakra-colors-white);
  --error: var(--chakra-colors-red-500);
}

.dark-theme {
  --light-gray: var(--chakra-colors-gray-600);
  --gray: var(--chakra-colors-gray-500);
  --brand700: var(--chakra-colors-brand-600);
  --brand600: var(--chakra-colors-brand-300);
  --brand500: var(--chakra-colors-brand-500);
  --brand400: var(--chakra-colors-brand-600);
  --brand300: var(--chakra-colors-brand-700);
  --brand200: var(--chakra-colors-brand-600);
  --brand100: var(--chakra-colors-brand-800);

  --monthBackground: var(--chakra-colors-gray-700);
  --text: var(--chakra-colors-gray-200);
  --negative-text: var(--chakra-colors-black);
  --error: var(--chakra-colors-red-300);
}

.react-datepicker__year-select,
.react-datepicker__year-select > option {
  background-color: var(--light-gray);
  padding: 0.25rem 1rem;
  color: var(--text);
  font-weight: bold;
}

.react-datepicker__year-select:active,
.react-datepicker__year-select:focus-visible {
  box-shadow: none;
  outline: none;
  border: 1px solid;
  border-color: var(--gray);
}
.react-datepicker {
  font-family: unset;
  font-size: 0.9rem;
  border-color: var(--light-gray);
}

.react-datepicker-wrapper,
.react-datepicker__input-container {
  display: block;
}

.react-datepicker__input-container {
  font-size: 1rem;
  height: 2.5rem;
}

.react-datapicker__input-text {
  background-color: transparent;
  width: 100%;
  padding-left: 1rem;
  padding-right: 1rem;
  border-radius: 0.25rem;
  border: 1px solid;
  border-color: var(--light-gray);
}

/*
  invalid form
 */
.react-datapicker__input-text.invalid {
  border: 2px solid;
  border-color: var(--error);
}

.react-datepicker__input-container:hover {
  border-color: var(--gray);
}
.react-datepicker__input-container:focus-within {
  z-index: 1;
  border-color: var(--brand600);
  box-shadow: 0 0 0 1px var(--brand600);
}

.react-datepicker__input-container > input {
  width: 100%;
  height: 100%;
  outline: 0;
}

.react-datepicker__navigation--next--with-time:not(.react-datepicker__navigation--next--with-today-button) {
  right: 90px;
}

.react-datepicker__navigation--previous,
.react-datepicker__navigation--next {
  height: 24px;
  top: 12px;
}

.react-datepicker__navigation--previous {
  border-color: var(--brand500);
}

.react-datepicker__navigation--previous:hover {
  border-color: var(--brand400);
}

.react-datepicker__navigation--next {
  border-left-color: var(--brand500);
}

.react-datepicker__navigation--next:hover {
  border-left-color: var(--brand400);
}

.react-datepicker__header {
  background-color: var(--light-gray);
}

.react-datepicker__header,
.react-datepicker__time-container {
  border-color: var(--brand300);
}

.react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker-year-header {
  font-size: inherit;
  font-weight: 600;
  color: var(--text);
}

.react-datepicker__month {
  background-color: var(--monthBackground);
  margin: 0;
  padding: 0.4rem;
}

.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list
  li.react-datepicker__time-list-item {
  margin: 0 1px 0 0;
  height: auto;
  padding: 7px 10px;
}

.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list
  li.react-datepicker__time-list-item:hover {
  background: var(--brand200);
}

.react-datepicker__day {
  color: var(--text);
}

.react-datepicker__day:hover {
  background: var(--brand200);
}

.react-datepicker__day-name {
  color: var(--text);
}

.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list
  li.react-datepicker__time-list-item--selected {
  background: var(--brand600);
  font-weight: normal;
  color: var(--negative-text);
}

.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list
  li.react-datepicker__time-list-item--selected:hover {
  background: var(--brand700);
}

.react-datepicker__close-icon::after {
  background-color: unset;
  border-radius: unset;
  font-size: 1.5rem;
  font-weight: bold;
  color: var(--light-gray);
  height: 20px;
  width: 20px;
}

.react-datepicker__close-icon::after:hover {
  color: var(--gray);
}

.react-datepicker__year-read-view--down-arrow {
  top: 4px;
  right: -24px;
}

.react-datepicker__year-read-view--selected-year {
  color: var(--text);
}
